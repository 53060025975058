<template>
  <div>
    <div class="groupData" @click="$root.$emit('bv::hide::popover')">
      <b-modal id="downloadSubgroupReport" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer
        :title="subgroupReport.name" size="lg">
        <SubgroupReports :subgroupReport="subgroupReport" />
      </b-modal>
      <b-modal :title="
        getCustomNames.userSubGroupAdmin.custom_name != null
          ? getCustomNames.userSubGroupAdmin.custom_name + ' List'
          : 'Usersubgroup Admin List'
      " size="lg" id="subgroupAdmins" hide-footer centered @close="checkAdminsStatus">
        <ListOfAdmins :editInfo="editInfo" :adminList="adminList"
          @updateInstitutionUserCount="updateInstitutionUserCount" :getCustomNames="getCustomNames" />
      </b-modal>
      <b-modal :title="'Add/View Users to/from ' + editInfo.name" size="lg" id="groupUsers" hide-footer centered>
        <GroupUser :editInfo="editInfo" @updateInstitutionUserCount="updateInstitutionUserCount" />
      </b-modal>
      <b-overlay :show="groupLoading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
        <div class="">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark">
              <tr>
                <th>Si.No</th>
                <th class="align-middle">
                  {{
                      getCustomNames.userSubgroup.custom_name != null
                        ? getCustomNames.userSubgroup.custom_name
                        : "Usersubgroup"
                  }}
                </th>
                <th class="align-middle">Belongs to (Scheme)</th>
                <th class="align-middle">Academic Year</th>
                <th class="align-middle">
                  {{
                      getCustomNames.userSubGroupAdmin.custom_name != null
                        ? getCustomNames.userSubGroupAdmin.custom_name + " List"
                        : "Usersubgroup Admin List"
                  }}
                </th>
                <th class="align-middle">
                  Enroll To
                  {{
                      getCustomNames.userSubgroup.custom_name != null
                        ? getCustomNames.userSubgroup.custom_name
                        : "Usersubgroup"
                  }}
                </th>

                <th class="align-middle">Report</th>
                <th>View Courses</th>
                <th class="align-middle"></th>
              </tr>
            </thead>
            <tbody v-for="({
            si_no,
            id,
            name,
            academic_year,
            academic_semester,
            institution_users,
            usergroup,
            code,
            students_count,
            type,
            deleted_at,
          },
            groupIndex) in groups" :key="groupIndex">
              <tr
                v-if="(subgroupCouresExpandable == null || (subgroupCouresExpandable != null && subgroupCouresExpandable == id))">
                <td>{{ si_no }}</td>
                <td class="align-middle">
                  <div>
                    <span
                      :class="{ 'text-primary': type == 'interdepartment_courses_group', 'text-danger': deleted_at != null }">{{
                          name + " (" + code +
                          ")"
                      }}</span>
                    <span v-if="mainGroupAdmin || userGroupAdmin" class="text-info btn p-0 m-0 ml-2"
                      @click="getGroup(id, institution_users)"><i class="fas fa-pen"></i></span>
                    <div v-if="(deleted_at != null)">
                      <vue-countdown :time="timeToDeleteIn(deleted_at)" v-slot="{ hours, minutes, seconds }">
                        <b-badge variant="danger" style="white-space: nowrap; margin: 2px;">Delete in
                          {{ hours }}:{{ minutes }}:{{ seconds }}
                        </b-badge><b-link
                          @click="deleteUsersubgroupModel.id = id; $bvModal.show('cancelDeleteSubgroupConfirmationModel')">
                          <b-badge variant="success">Cancel Deletion</b-badge></b-link>
                      </vue-countdown>
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  {{ usergroup.name }}({{usergroup.scheme}})
                </td>
                <td class="align-middle">
                  {{ academic_year == null ? "-" : academic_year }}
                  {{ academic_semester }}
                </td>
                <td class="align-middle">
                  <b-popover :target="'usergroupAdmin' + groupIndex" triggers="click" placement="top"
                    v-if="institution_users.length != 0">
                    <b-list-group style="height: 200px; overflow-y: scroll">
                      <b-list-group-item v-for="({ first_name, last_name },
                      adminIndex) in institution_users" :key="adminIndex">
                        {{ first_name.concat(" ", last_name) }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-popover>
                  <div class="d-flex justify-content-between align-items-baseline">
                    <span class="btn p-0 m-0 text-info font-weight-bolder" v-if="institution_users.length == 1" @click="
                      openAdminsModal({
                        id,
                        name,
                        group: false,
                        dataChanged: false,
                      })
                    ">
                      {{
                          institution_users[0].first_name.concat(
                            institution_users[0].last_name
                          )
                      }}
                    </span>
                    <span class="btn p-0 m-0 text-info font-weight-bolder" v-else-if="institution_users.length > 1"
                      @click="
                        openAdminsModal({
                          id,
                          name,
                          group: false,
                          dataChanged: false,
                        })
                      ">
                      {{
                          institution_users[0].first_name.concat(
                            " ",
                            institution_users[0].last_name
                          )
                      }}
                      & {{ institution_users.length - 1 }} more
                    </span>
                    <span class="btn m-0 p-0 text-danger font-weight-bolder" @click="
                      openAdminsModal({
                        id,
                        name,
                        group: false,
                        dataChanged: false,
                      })
                    " v-else>
                      No Admins Assigned Yet!
                    </span>
                    <span :id="'usergroupAdmin' + groupIndex" class="btn m-0 p-0 text-info">
                      <i class="fas fa-bars"></i>
                    </span>
                  </div>
                </td>
                <td class="align-middle">
                  <span v-if="type != 'interdepartment_courses_group'" class="text-info font-weight-bolder btn p-0 m-0"
                    @click="
                      $emit('inviteEnroll', { type: 'Subgroup', id, name })
                    ">
                    Enroll Students </span><span v-if="students_count > 0">-({{ students_count }})</span>
                </td>

                <td class="align-middle">
                  <span :id="'usergroupAdmin' + groupIndex" class="btn p-0 m-0 text-info"
                    @click="getSubgroupReports(id, name, code, usergroup.code)">
                    <i class="fas fa-file-alt"></i>
                  </span>
                </td>

                <th>
                  <span class="text-info" style=" text-decoration: underline; "
                    v-b-toggle="'toggleSubGroupCourses' + id"
                    @click="subgroupCouresExpandable = subgroupCouresExpandable == id ? null : id"> <i
                      v-if="(subgroupCouresExpandable != id)" class="fas fa-plus text-success"></i> <i
                      v-if="(subgroupCouresExpandable == id)" class="fas fa-minus text-danger"></i>Courses List
                  </span>
                </th>
                <td class="align-middle" v-if="mainGroupAdmin || userGroupAdmin">
                  <span class="btn p-0 m-0" @click="duplicateUsersubgroup(id, name, code, false)"> <i style="color:green;"
                      class="fas fa-copy my-1 mx-2"></i></span>
                  <span v-if="(enableDeleteSubgroupButton && deleted_at == null)" :id="'usergroupAdmin' + groupIndex"
                    class="btn p-0 m-0 text-info" @click="openDeleteUsersubgroupModel(id, name)">
                    <i style="color:red;" class="fas fa-trash"></i>
                  </span>
                  <span v-if="
                    !enableDeleteSubgroupButton && subgroupReport.id == id
                  ">deleting...</span>
                </td>
                <td v-else></td>
              </tr>

              <tr :style="{
                display: subgroupCouresExpandable == id ? '' : 'none',
              }">
                <td colspan="100">
                  <b-collapse v-if="subgroupCouresExpandable == id" :id="'toggleSubGroupCourses' + id">
                    <CourseListing :editCourseComponents="false" :usersubgroupId="id" :subgroupType="type"
                      @closeList="(subgroupCouresExpandable = null)" />
                  </b-collapse>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </div>


    <b-modal id="deleteSubgroupConfirmationModel" hide-header-close hide-footer hide-header centered size="sm">
      <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
        Are you sure you want to delete {{ deleteUsersubgroupModel.name }}
      </h6>
      <div>
        <b-form-group id="input-group-1" label-for="input-1">
          <label class="required-label" for="">Type 'DELETE' and press delete button to complete deletion:</label>
          <b-form-input id="input-1" v-model="deleteUsersubgroupModel.confirmText" type="text"
            placeholder="Enter  DELETE"></b-form-input>
        </b-form-group>
      </div>
      <div>
        <b-form-group id="input-group-1" label-for="input-1">
          <label class="required-label" for="">Enter a brief explanation of the reason for deletion (Minimum 10
            character):</label>
          <b-form-input id="input-1" v-model="deleteUsersubgroupModel.reason" type="text"
            placeholder="Enter Reason"></b-form-input>
        </b-form-group>
      </div>
      <div class="buttons">
        <b-button
          :disabled="(deleteUsersubgroupModel.confirmText != 'DELETE' || deleteUsersubgroupModel.reason.length < 10)"
          size="sm" variant="primary" @click="deleteUsersubgroup()">Delete</b-button>
        <b-button size="sm" variant="danger" class="ml-2"
          @click="$bvModal.hide('deleteSubgroupConfirmationModel')">Cancel</b-button>
      </div>
    </b-modal>

    <b-modal id="cancelDeleteSubgroupConfirmationModel" hide-header-close hide-footer hide-header centered size="sm">
      <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
        Are you sure you want to proceed
      </h6>
      <div>
        <b-form-group id="input-group-1" label-for="input-1">
          <label class="required-label" for="">Enter a brief explanation of the reason for cancellation of
            deletion (Minimum 10 character):</label>
          <b-form-input id="input-1" v-model="deleteUsersubgroupModel.reason" type="text"
            placeholder="Enter Reason"></b-form-input>
        </b-form-group>
      </div>
      <div class="buttons">
        <b-button :disabled="(deleteUsersubgroupModel.reason.length < 10)" size="sm" variant="primary"
          @click="CancelDeletion()">Cancel Deletion</b-button>
        <b-button size="sm" variant="danger" class="ml-2"
          @click="$bvModal.hide('cancelDeleteSubgroupConfirmationModel')">Cancel</b-button>
      </div>
    </b-modal>

    <b-modal id="duplicateUsersubgroupModel" hide-header-close hide-footer hide-header centered>
      <h6 style="font-size: 13px; font-weight: 600" class="text-danger">
        Do you want to proceed with creating a copy of {{ duplicateSubgroup.name }} ?
      </h6>
      <b-form-group id="input-group-1" label-for="input-1">
        <label class="required-label" for="">Name of new {{ this.subgroupCustomName }} :</label>
        <b-form-input id="input-1" v-model="duplicateSubgroup.newName" type="text"
          placeholder="Enter Reason"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-1" label-for="input-1">
        <label class="required-label" for="">Code of new {{ this.subgroupCustomName }} :</label>
        <b-form-input id="input-1" v-model="duplicateSubgroup.newCode" type="text"
          placeholder="Enter Reason"></b-form-input>
      </b-form-group>
      <div class="m-3">
        <b-form-checkbox v-model="duplicateSubgroup.copyCourseFaculty">
          <span class="">Copy course instructor mapping
            ?</span>
        </b-form-checkbox>
      </div>
      <div class="buttons">
        <b-button size="sm" variant="primary"
          @click="duplicateUsersubgroup(duplicateSubgroup.id, duplicateSubgroup.name, duplicateSubgroup.code, true)">
          {{ duplicateSubgroup.loading ? 'Loading...' : 'Start Copy' }}
        </b-button>
        <b-button size="sm" variant="danger" class="ml-2"
          @click="$bvModal.hide('duplicateUsersubgroupModel')">Cancel</b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
import ListOfAdmins from "./ListOfAdmins";
import SubgroupReports from "../Institute/usergroups/SubgroupReports";
import CourseListing from "../Course/CourseListing.vue";

import moment from 'moment';
export default {
  props: [
    "selectedInstitute",
    "adminList",
    "groups",
    "getCustomNames",
    "mainGroupAdmin",
    "userGroupAdmin",
    "subgroupAdmin",
  ],
  async created() {
    this.subgroupCustomName = this.$store.getters.getCustomNamings.userSubgroup.custom_name ?? "Usersubgroup";
  },

  components: {
    ListOfAdmins,
    SubgroupReports,
    CourseListing,
    VueCountdown,
  },
  data() {
    return {
      subgroupCustomName: '',
      editInfo: {},
      groupLoading: false,
      subgroupReport: {},
      enableDeleteSubgroupButton: true,
      subgroupCouresExpandable: null,
      deleteUsersubgroupModel: { id: null, name: null, confirmText: '', reason: '' },
      duplicateSubgroup: { id: null, name: '', loading: false, copyCourseFaculty: false, newName: '' },
    };
  },
  methods: {
    async duplicateUsersubgroup(id, name, code, confirm) {
      if (confirm == false) {
        this.duplicateSubgroup = { id: id, name: name, code: code, loading: false, copyCourseFaculty: false, newName: name + '-copy', newCode: code + '-copy' };
        this.$bvModal.show("duplicateUsersubgroupModel");
      } else {
        this.duplicateSubgroup.loading = true;
        const url = this.$store.getters.getAPIKey.mainAPI + `/usersubgroup/duplicate`;
        await this.$axios
          .post(url, { from_usersubgroup: this.duplicateSubgroup.id, fresh_copy: true, course_instructors: this.duplicateSubgroup.copyCourseFaculty, name: this.duplicateSubgroup.newName, code: this.duplicateSubgroup.newCode })
          .then(() => {
            this.$toast.success('new ' + this.subgroupCustomName + ' created successfully', {
              position: "top",
              duration: 3000,
            });
            this.$emit("GroupCreated", true);
          })
          .catch(() => {
            this.$toast.error("Failed to copy " + this.subgroupCustomName, {
              position: "top",
              duration: 7000,
            });
          });
        this.$bvModal.hide('duplicateUsersubgroupModel');
        this.duplicateSubgroup = { id: null, name: '', code:'', loading: false, copyCourseFaculty: false, newName: '', newCode: '' };
      }

      // var result = confirm("Do you want to proceed to copy " + name + " ? new " + this.subgroupCustomName + " will be created on the same programme and academic year with a name " + name + "-copy");

    },
    timeToDeleteIn(deleted_at) {
      // Step 1: Add 24 hours to the API response time
      const futureTime = moment.utc(deleted_at, 'YYYY-MM-DD HH:mm:ss').add(24, 'hours');
      // Step 2: Get the current UTC time using moment.js
      const currentUTCTime = moment.utc();
      // Step 3: Calculate the difference in seconds
      const timeDifferenceInSeconds = futureTime.diff(currentUTCTime, 'seconds');
      return timeDifferenceInSeconds * 1000;
    },
    async CancelDeletion() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/usersubgroups/${this.deleteUsersubgroupModel.id}/canceldeletion`;
      this.$axios
        .post(url, { reason: this.deleteUsersubgroupModel.reason })
        .then(() => {
          this.deleteUsersubgroupModel = { id: null, name: null, confirmText: '', reason: '' };
          this.$emit("GroupCreated", true);
        });
    },
    getSubgroupReports(id, name, code ,usergroup_code) {
      this.subgroupReport.id = id;
      this.subgroupReport.name = name;
      this.subgroupReport.code = code;
      this.subgroupReport.usergroup_code = usergroup_code;
      this.$bvModal.show("downloadSubgroupReport");
    },
    openDeleteUsersubgroupModel(id, name) {
      this.deleteUsersubgroupModel = { id: id, name: name, confirmText: '', reason: '' };
      this.$bvModal.show("deleteSubgroupConfirmationModel");
    },
    deleteUsersubgroup() {
      let id = this.deleteUsersubgroupModel.id;
      let name = this.deleteUsersubgroupModel.name;
      this.enableDeleteSubgroupButton = false;
      this.subgroupReport.id = id;
      this.subgroupReport.name = name;
      if (
        confirm(
          'WARNING: You are going to delete "' +
          name +
          '". that will course deleting all the data related to them also (eg: courses, co, dettach course instructors, course scedules, boardexam config, modules, lessonplans, co_po_pso_mapping,course_properties, text_books, reference_docs...) '
        ) == true
      ) {
        const url =
          this.$store.getters.getAPIKey.mainAPI + `/usersubgroups/${id}`;
        this.$axios
          .delete(url, {
            data: { reason: this.deleteUsersubgroupModel.reason },
          })
          .then(() => {
            // console.log(response)
            this.$toast.success(`${name} deletion initiated successfully`, {
              position: "top-right",
              duration: 6000,
            });
            this.enableDeleteSubgroupButton = true;

            this.deleteUsersubgroupModel = { id: null, name: null, confirmText: '', reason: '' };
            this.$emit("GroupCreated", true);
          })
          .catch((error) => {
            // console.log(error);
            this.$toast.error(
              `${name} is failed to delete, ` + error.response.data.message,
              {
                position: "top-right",
                duration: 6000,
              }
            );
            error;
            this.enableDeleteSubgroupButton = true;
          });
      } else {
        this.enableDeleteSubgroupButton = true;
      }
    },
    async getGroup(groupId, admins) {
      this.groupLoading = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/usersubgroups/${groupId}`;
      const group = await this.$axios.get(url).then((res) => {
        return res.data;
      });
      const groupData = {
        group,
        admins,
        status: false,
      };
      this.$emit("getGroup", groupData);
      this.groupLoading = false;
    },
    openAdminsModal(info) {
      this.editInfo = info;
      this.$bvModal.show("subgroupAdmins");
    },
    openGroupUsersModal(info) {
      this.editInfo = info;
      this.$bvModal.show("groupUsers");
    },
    updateInstitutionUserCount(status) {
      this.editInfo.dataChanged = status;
    },
    async checkAdminsStatus() {
      if (this.editInfo.dataChanged) {
        this.$emit("adminsUpdated");
      }
    },
  },
};
</script>

<style >
.groupData i {
  font-size: 18px !important;
}
</style>
