<template>
  <div>
    <!-- <div class="loading" v-if="loadTimetable">
      <load-data></load-data>
    </div> -->
    <div class="container">
      <!--Add class type and selivery method-->
      <b-modal id="classtypeDeliverymethodModel" hide-footer centered size="md">
        <b-form-group label="Class Type:">
          <b-form-radio-group v-model="timetableClasstypeDeliverymethod.class_type"
            :name="('classtype-radios' + sessionId)" :options="classTypes">
          </b-form-radio-group>
        </b-form-group>
        <b-form-group label="Delivery methods:">
          <b-form-checkbox-group v-model="timetableClasstypeDeliverymethod.delivery_method_id"
            :options="courseDeliveryMethods">
          </b-form-checkbox-group>
        </b-form-group>
        <div class="d-flex justify-content-between">
          <b-button class="btn btn-success" size="sm" @click="saveClassTypeDeliveryMethod()">Save</b-button>
          <b-button @click="clear()" variant="primary" size="sm">Clear</b-button>
          <b-button @click="$bvModal.hide('classtypeDeliverymethodModel')" variant="danger" size="sm">Cancel</b-button>
        </div>
      </b-modal>
      <div class="col-md-12">
        <b-form-group style="margin-bottom: 0px !important;"
          :label="('Choose ' + subgroupCustomName+' (programme-Scheme)')">
          <select class="custom-select" v-model="subgroup" @change="changeSubgroup()">
            <optgroup v-for="(group, groupindex) in subgroupOptions" :key="groupindex" :label="group.name">
              <option v-for="(subgroup, subgroupindex) in group.subgroups" :key="subgroupindex" :value="subgroup.value">
                {{ subgroup.text }}</option>
            </optgroup>
          </select>
        </b-form-group>
      </div>
    </div>
    <br> <br>
    <div v-if="subgroup">
      <div class="container">
        <b-row class="control-buttons m-1">
          <b-col v-if="timetablesAction == 'list'" style="text-align:left;">
            <b-button variant="info" class="text-white sm" size="sm" @click="createEditTimetable(null)"><i
                class="fas fa-plus-square text-white mr-1"></i>Add New Timetable</b-button>
            <b-button variant="info" class="ml-2 text-white sm" size="sm"
              @click="$bvModal.show('usersubgroupHolidaysModel');"><i class="fas fa-plus-square text-white mr-1"></i>Add
              Holidays</b-button>
            <b-button variant="info" class="ml-2 text-white sm" size="sm"
              @click="$bvModal.show('usersubgroupClassSuspendModel');"><i
                class="fas fa-plus-square text-white mr-1"></i>Suspend Session</b-button>
          </b-col>
          <b-col v-if="timetablesAction != 'list'" style="text-align:right;">
            <b-button variant="danger" class="text-white sm" size="sm" @click="changeSubgroup"
              :disabled="actionButton.disabled">Cancel</b-button>
          </b-col>
        </b-row>
        <b-row v-if="timetablesAction == 'list'">
          <b-col sm="12">
            <h3 style="text-align: center; font-size:18px !important;">
              Timetables
            </h3>
            <table class="table  table-striped table-bordered">
              <tr>
                <th>
                  Priority
                </th>
                <th>
                  Name
                </th>
                <th>
                  Description
                </th>
                <th>
                  Date
                </th>
                <th>Type</th>
                <th></th>
              </tr>
              <tr v-for="(timetable, reindex) in timetables" :key="reindex">
                <td>{{ timetable.si_no }}</td>
                <td>
                  {{ timetable.name }} <span v-b-popover.hover.top="'Default timetable will be used for reports'"
                    class="text-success" v-if="timetable.default_timetable">(Default)</span>
                </td>
                <td>{{ timetable.description }}</td>
                <td>{{ moment(timetable.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}
                  : {{ moment(timetable.upto_date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}</td>
                <td>{{ timetable.type == 'weeks' ? 'Week Timetable' : 'Day Timetable' }}</td>
                <td>
                  <span v-b-popover.hover.top="'Default timetable will be used for reports'" size="sm"
                    @click="setDefaultTimetable(reindex)" class="text-warning btn m-0 p-0 sm"
                    v-if="!timetable.default_timetable">
                    Set Default</span>
                  <span class="text-info btn m-0 p-0 sm" size="sm" @click="createEditTimetable(reindex)"
                    :disabled="actionButton.disabled"><i class="fas fa-pen ml-3"></i></span>
                  <span class="text-info btn m-0 p-0 sm" size="sm"
                    @click="deleteTimetable(timetable.id, timetable.name)" :disabled="actionButton.disabled"><i
                      style="color:red;" class="fas fa-trash ml-3"></i></span>
                  <span class="text-info btn m-0 p-0 sm" size="sm" @click="downloadTimetable(timetable.id)"
                    :disabled="actionButton.disabled"><i style="color:green;" class="fa fa-file-pdf-o ml-3"></i></span>
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </div>
      <!-- schedules  -->
      <div v-if="timetablesAction == 'list'">
        <b-row>
          <b-col sm="12">
            <h3 style="text-align: center; font-size:18px !important;">
              Schedules
            </h3>

            <b-row class="mx-2">
              <div style="border:none;margin-bottom: 1px;"> <span class="px-3 holiday-slot  mr-1 border-dark"
                  style="border: 2px solid;"></span>Holiday.
              </div>
              <div class="ml-2" style="border:none;margin-bottom: 1px;"> <span
                  class="px-3 suspended-slot  mr-1 border-dark" style="border: 2px solid;"></span>Class Suspended.
              </div>
            </b-row>
            <table v-if="viewSubgroupSchedule" class="table table-striped table-bordered">
              <thead>
                <th style="width: 10%; border: 1px solid black" class="text-center">
                  <span class="text-dark"><i class="fas fa-user-clock fa-lg"></i></span>
                </th>
                <th v-for="sessionId in subgroupSchedulesEnabledSessionIds" :key="sessionId"
                  style="width: 10%; border: 1px solid black" class="text-center bg-light">
                  {{ sessionObjects[sessionId].name }}
                </th>
              </thead>

              <tbody>
                <tr v-for="(sessionsSchedules, date) in datesSessionsSubgroupSchedules" :key="date"
                  style="border: 1px solid red; height: 40px;" :class="{ 'holiday-row': date in holidays, }"
                  v-b-popover.hover.top="(date in holidays ? 'Holiday: ' + holidays[date] : '') + (date in suspends ? ' Class Suspended: ' + suspends[date].name : '')">
                  <td style="width: 10%; border: 1px solid black">
                    {{ moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}-{{ weekdayNames[new Date(date).getDay()] }}
                  </td>

                  <td v-for="sessionId in subgroupSchedulesEnabledSessionIds" :key="sessionId"
                    style="width: 10%; border: 1px solid #dcdcdc; padding: 1px"
                    :class="{ 'suspended-cell': (date in suspends ? (suspends[date].session_id.includes(sessionId)) : false) }">
                    <div v-for="(schedule, scheduleIndex) in sessionsSchedules[sessionId]" :key="scheduleIndex"
                      :class="('d-flex flex-column m-1 badge ' + badgeColors[schedule.course_id.toString().slice(-1)])"
                      v-b-popover.hover.top="
                        schedule.course +
                        '-' + schedule.subgroup_code + '-' +
                        schedule.instructor_fullname
                      ">
                      <span style="color : blue;font-size:8px !important;" v-if="schedule.class_type">{{
                        schedule.class_type.name }}</span><br>{{
                      schedule.course_code }} {{ schedule.instructor }}<br><span
                        style="color : blue;font-size:8px !important;" v-if="schedule.delivery_method">{{
                        schedule.delivery_method.name }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else>Loading Schedules...</div>
          </b-col>
        </b-row>
      </div>
      <b-row v-if="timetablesAction == 'createEdit'">
        <b-col>
          <div class="container">
            <h3 style="text-align: center; font-size:18px !important;">
              {{ timetable.id ? 'Edit ' + timetable.name : 'Create Timetable' }}
            </h3>
            <div v-if="enableEditTimetable">
              <b-row>
                <b-col sm="6">
                  <b-form-group id="input-group-1" label-for="input-1" description="">
                    <label class="required-label" for="">Priority:</label>
                    <b-form-input id="input-1" v-model="timetable.si_no" type="text" placeholder="Serial Number"
                      required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group id="input-group-2" label-for="input-2" description="">
                    <label class="required-label" for="">Type:</label>
                    <b-form-select :disabled="(timetable.id != null)" v-model="timetable.type"
                      :options="[{ text: 'Day Timetable', value: 'days' }, { text: 'Week Timetable', value: 'weeks' },]"></b-form-select>
                  </b-form-group>
                  <span class="text-danger" v-if="isDayTimetable">
                    Create timetable for number of days (eg: 3 days or 8 days). then the the timetable repeat
                    independent
                    of weekdays(monday, wednesday, ...)
                  </span>
                  <span class="text-danger" v-else>Create Timetable for a week, so the timetable will repeat for every
                    week
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <b-form-group id="input-group-1" label-for="input-1" description="">
                    <label class="required-label" for="">Name:</label>
                    <b-form-input id="input-1" v-model="timetable.name" type="text"
                      placeholder="eg: Regular Timetable, Special Timetable" required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group id="input-group-2" label="Description:" label-for="input-2" description="">
                    <b-form-input id="input-2" v-model="timetable.description" type="text"
                      placeholder="Enter a Description" required></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <b-form-group id="input-group-1" label-for="input-1" description="">
                    <label class="required-label" for="">Start Date:</label>
                    <b-form-datepicker id="example-datepicker" v-model="timetable.start_date"
                      class="mb-2"></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group id="input-group-2" label-for="input-2" description="">
                    <label class="required-label" for="">End Date:</label>
                    <b-form-datepicker id="example-datepicker1" v-model="timetable.upto_date"
                      class="mb-2"></b-form-datepicker>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <b-form-group id="input-group-1" label="Excluded Dates:" label-for="input-1" description="">
                    <b-form-tags input-id="tags-basic2" v-model="timetable.excluded_dates" separator=" ,;"
                      placeholder="Enter dates separated by comma (eg: 2023-09-16, 2023-09-17, ..)"></b-form-tags>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group id="input-group-2" label="Excluded Week Days:" label-for="input-2" description="">
                    <b-form-tags input-id="tags-basic1" v-model="timetable.excluded_weeks" separator=" ,;"
                      placeholder="Enter week day separated by comma (eg: Saturday, Sunday, ..)"></b-form-tags>
                  </b-form-group>
                </b-col>
              </b-row>
              <br />
              <b-row v-if="enableEditTimetable && !enableEditTimetableSchedule">
                <b-col sm="12" style="text-align:right;">
                  <b-button variant="success" class="text-white sm" size="sm" @click="postCreateTimetable"
                    :disabled="actionButton.disabled">{{ actionButton.text }}</b-button>
                </b-col>
              </b-row>
            </div>
            <!-- <div v-else>
              <b-row>
                <b-col sm="6">Priority:{{ timetable.si_no }}</b-col>
                <b-col sm="6">Type:{{ timetable.type }}</b-col>
              </b-row>
              <b-row>
                <b-col sm="6">Name:{{ timetable.name }}</b-col>
                <b-col sm="6">Description:{{ timetable.description }}</b-col>
              </b-row>
              <b-row>
                <b-col sm="6">Start Date:{{ timetable.start_date }}</b-col>
                <b-col sm="6">Upto Date:{{ timetable.upto_date }}</b-col>
              </b-row>
              <b-row>
                <b-col sm="6">Excluded Dates:{{ timetable.excluded_dates }}</b-col>
                <b-col sm="6">Excluded Weeks: {{ timetable.excluded_weeks }}</b-col>
              </b-row>
            </div> -->
          </div>

          <br><br>
          <div v-if="enableEditTimetableSchedule">
            <h3 style="text-align: center; font-size:15px !important;">Configure Timetable Slots</h3>
            <br>
            <draggable class="mx-3 my-1 dragArea border border-info" :list="courseInstructorArray"
              :group="{ name: 'schedule', pull: 'clone', put: false }" tag="b-row" :animation="200">
              <b-col class="my-1 text-center" v-for="courseInstructor in courseInstructorArray"
                :key="courseInstructor.course_user_id">
                <span style="white-space: nowrap; cursor: -webkit-grab; cursor: grab;"
                  :class="('badge shadow ' + badgeColors[courseInstructor.course_id.toString().slice(-1)])">
                  {{ courseInstructor.course_code }}-<small>{{
                    courseInstructor.instructor
                    }}</small>
                </span>
              </b-col>
            </draggable>
            <b-row class="my-1">
              <b-col> <span v-if="isDayTimetable" @click="addDayToTimetableScheduleTable"
                  style="white-space: nowrap; cursor: -webkit-grab; cursor: pointer;"
                  class="badge shadow badge-success">Add Day</span>
                <span @click="addSessionToTimetableScheduleTable"
                  style="white-space: nowrap; cursor: -webkit-grab; cursor: pointer;"
                  class="mx-1 badge shadow badge-success">Add Session</span> </b-col>
            </b-row>
            <div class="">
              <table class="table table-striped table-bordered">
                <thead>
                  <th style="width: 10%; border: 1px solid black" class="text-center">
                    <span class="text-dark"><i class="fas fa-user-clock fa-lg"></i></span>
                  </th>
                  <th v-for="n in maxSessions" :key="n + 'th'" scope="col" style="width: 10%; border: 1px solid black"
                    class="text-center bg-light">
                    {{ sessions[n - 1].name }}
                  </th>
                </thead>

                <tbody>
                  <tr v-for="(day, dayIndex) in courseSchedulesTableHeaders" :key="dayIndex"
                    style="border: 1px solid black;     height: 40px;">
                    <td style="width: 10%; border: 1px solid black">
                      {{ isDayTimetable ? 'Day ' + day.id : day.name }}
                    </td>

                    <draggable v-for="(session, sessionIndex) in day.sessions" :key="sessionIndex"
                      style="width: 10%; border: 1px solid #dcdcdc; padding: 1px"
                      :list="courseSchedulesTableData[day.id][session.id]" group="schedule" @change="forceUpdateIt"
                      tag="td" :animation="200">
                      <div v-for="(schedule, scheduleIndex) in courseSchedulesTableData[day.id][session.id]"
                        :key="scheduleIndex"
                        :class="('d-flex flex-column m-1 badge ' + badgeColors[schedule.course_id.toString().slice(-1)])"
                        v-b-popover.hover.top="schedule.course +
                          '-' +
                          schedule.instructor_fullname
                          ">
                        <p @click="SelectClassTypeDeliveryMethod(schedule.course_id, day.id, session.id)">
                          <span
                            v-if="courseSchedulesClasstypeDeliverymethodArray[day.id][session.id][schedule.course_id]"
                            style="color : blue;font-size:8px !important;">
                            {{
                            courseSchedulesClasstypeDeliverymethodArray[day.id][session.id][schedule.course_id]['class_type']
                            }}</span>
                          <br>
                          {{
                          schedule.course_code }} {{
                          schedule.instructor }} <br><span
                            v-if="courseSchedulesClasstypeDeliverymethodArray[day.id][session.id][schedule.course_id]"
                            style="color : blue;font-size:8px !important;">
                            {{
                            courseSchedulesClasstypeDeliverymethodArray[day.id][session.id][schedule.course_id]['delivery_method']
                            }}</span>
                        </p>
                        <i @click="
                          deleteCourseFromList(
                            scheduleIndex,
                            day.id,
                            session.id
                          )
                          " style="font-size: 12px !important;"
                          class="fas fa-times-circle btn m-0 p-0 text-danger"></i>
                      </div>
                    </draggable>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <b-row>
              <b-col sm="12" style="text-align:right;">
                <b-button variant="success" class="text-white sm" size="sm" @click="updateTimetableSchedules"
                  :disabled="actionButton.disabled">{{ actionButton.text }}</b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal v-if="subgroup" id="usersubgroupHolidaysModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc
      hide-footer centered size="lg">
      <div class="container">
        <UsersubgroupHolydays :usersubgroup_id="subgroup" />
      </div>
    </b-modal>
    <b-modal v-if="subgroup" id="usersubgroupClassSuspendModel" no-close-on-backdrop no-close-on-keyboard
      no-close-on-esc hide-footer centered size="lg">
      <div class="container">
        <usersubgroupSuspendClass :usersubgroup_id="subgroup" />
      </div>
    </b-modal>
  </div>
</template>

<script>
// import moment from 'moment';
import axios from "axios";
// import acl from "../../../../authorization/acl.js";
import usersubgroupSuspendClass from "../../../usersubgroupSuspendclass/dashboard.vue";
import UsersubgroupHolydays from "../../../usersubgroupHolydays/dashboard.vue";
export default {
  async created() {
    await this.getSubgroups();
    await this.getSessions();
    await this.getWorkdays();
    await this.getDeliverymethodsClassTypes();
  },
  data() {
    return {
      timetableClasstypeDeliverymethod:{
        delivery_method_id: null,
        class_type: null,
        day_id: null,
        course_id: null,
        session_id: null,
      },
      courseSchedulesClasstypeDeliverymethodArray:{},
      subgroup: null,
      subgroupOptions: {},
      subgroups: [],
      sessions: [],
      sessionObjects: [],
      workdays: [],
      workdayObjects: [],
      courseDeliveryMethods: [],
      classTypes:[],
      timetables: [],
      timetable: {
        id: null,
        si_no: '', name: '',
        type: '', //['days', 'weeks']
        description: '',
        excluded_dates: [],// YYYY/MM/DD
        excluded_weeks: [], // SUNDAY, MONDAY,...
        start_date: null,
        upto_date: null,
        default_timetable: null,
        usersubgroup_id: null,
      },
      timetablesAction: 'list',//[list,createEdit]
      actionButton: {
        text: "Save",
        disabled: false,
      },
      enableEditTimetable: false,
      enableEditTimetableSchedule: false,
      courseInstructorArray: [],
      timetableCourseSchedulesResponse: {},
      ClassDeliveryMethodResponse: {},
      courseSchedulesTableData: {},
      courseSchedulesTableHeaders: {},
      maxSessions: 0, // number of sessions used in the schedule table
      badgeColors: [
        'badge-red', 'badge-orange', 'badge-yellow', 'badge-green', 'badge-blue', 'badge-pink', 'badge-purple', 'badge-brown', 'badge-black', 'badge-white'
      ],
      viewSubgroupSchedule: false,
      datesSessionsSubgroupSchedules: {},
      subgroupSchedulesEnabledSessionIds: [],
      holidays: {}, //{ date:holyday name, ...}
      suspends: {},//{ date:{name, date, sessionIds}, ...}

      weekdayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    };
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
    subgroupCustomName() {
      return this.getCustomNames.userSubgroup.custom_name != null
        ? this.getCustomNames.userSubgroup.custom_name
        : "Usersubgroup";
    },
    isDayTimetable() {
      return this.timetable.type == 'days'
    }
  },
  components: {
    usersubgroupSuspendClass,
    UsersubgroupHolydays,
  },
  methods: {
    clear() {
      this.timetableClasstypeDeliverymethod.delivery_method_id = null;
      this.timetableClasstypeDeliverymethod.class_type = null;
      this.courseSchedulesClasstypeDeliverymethodArray[this.timetableClasstypeDeliverymethod.day_id][this.timetableClasstypeDeliverymethod.session_id][this.timetableClasstypeDeliverymethod.course_id]['class_type_id'] = null;
      this.courseSchedulesClasstypeDeliverymethodArray[this.timetableClasstypeDeliverymethod.day_id][this.timetableClasstypeDeliverymethod.session_id][this.timetableClasstypeDeliverymethod.course_id]['delivery_method_id'] = null;
      this.courseSchedulesClasstypeDeliverymethodArray[this.timetableClasstypeDeliverymethod.day_id][this.timetableClasstypeDeliverymethod.session_id][this.timetableClasstypeDeliverymethod.course_id]['class_type'] = null;
      this.courseSchedulesClasstypeDeliverymethodArray[this.timetableClasstypeDeliverymethod.day_id][this.timetableClasstypeDeliverymethod.session_id][this.timetableClasstypeDeliverymethod.course_id]['delivery_method'] = null;
    },
    async saveClassTypeDeliveryMethod() {
      this.courseSchedulesClasstypeDeliverymethodArray[this.timetableClasstypeDeliverymethod.day_id][this.timetableClasstypeDeliverymethod.session_id][this.timetableClasstypeDeliverymethod.course_id]['class_type_id'] = this.timetableClasstypeDeliverymethod.class_type;
      this.courseSchedulesClasstypeDeliverymethodArray[this.timetableClasstypeDeliverymethod.day_id][this.timetableClasstypeDeliverymethod.session_id][this.timetableClasstypeDeliverymethod.course_id]['delivery_method_id'] = this.timetableClasstypeDeliverymethod.delivery_method_id;
      this.courseDeliveryMethods.forEach((delivery_method) => {
        if (delivery_method.value == this.timetableClasstypeDeliverymethod.delivery_method_id) {
          this.courseSchedulesClasstypeDeliverymethodArray[this.timetableClasstypeDeliverymethod.day_id][this.timetableClasstypeDeliverymethod.session_id][this.timetableClasstypeDeliverymethod.course_id]['delivery_method'] = delivery_method.text;
        }
      })
      this.classTypes.forEach((class_type) => {
        if (class_type.value == this.timetableClasstypeDeliverymethod.class_type) {
          this.courseSchedulesClasstypeDeliverymethodArray[this.timetableClasstypeDeliverymethod.day_id][this.timetableClasstypeDeliverymethod.session_id][this.timetableClasstypeDeliverymethod.course_id]['class_type'] = class_type.text;
        }
      })
      this.timetableClasstypeDeliverymethod.day_id = null;
      this.timetableClasstypeDeliverymethod.session_id = null;
      this.timetableClasstypeDeliverymethod.course_id = null;
      this.timetableClasstypeDeliverymethod.delivery_method_id = null;
      this.timetableClasstypeDeliverymethod.class_type = null;
      this.$bvModal.hide('classtypeDeliverymethodModel');
    },
    async SelectClassTypeDeliveryMethod(course_id, day, session) {
      this.timetableClasstypeDeliverymethod.course_id = course_id;
      this.timetableClasstypeDeliverymethod.day_id = day;
      this.timetableClasstypeDeliverymethod.session_id = session;
      if (this.courseSchedulesClasstypeDeliverymethodArray[this.timetableClasstypeDeliverymethod.day_id][this.timetableClasstypeDeliverymethod.session_id][this.timetableClasstypeDeliverymethod.course_id] == null) {
        this.courseSchedulesClasstypeDeliverymethodArray[this.timetableClasstypeDeliverymethod.day_id][this.timetableClasstypeDeliverymethod.session_id][this.timetableClasstypeDeliverymethod.course_id] = {
          'delivery_method_id': null,
          'classt_type_id': null
        };
      }
      this.timetableClasstypeDeliverymethod.delivery_method_id =
        this.courseSchedulesClasstypeDeliverymethodArray[this.timetableClasstypeDeliverymethod.day_id][this.timetableClasstypeDeliverymethod.session_id][this.timetableClasstypeDeliverymethod.course_id]['delivery_method_id'];
      this.timetableClasstypeDeliverymethod.class_type =
        this.courseSchedulesClasstypeDeliverymethodArray[this.timetableClasstypeDeliverymethod.day_id][this.timetableClasstypeDeliverymethod.session_id][this.timetableClasstypeDeliverymethod.course_id]['class_type_id'];
      this.$bvModal.show('classtypeDeliverymethodModel');
    },
    async downloadTimetable(id) {
      const url = `${this.$store.getters.getAPIKey.mainAPI}/usersubgrouptimetables/${id}/report/pdfexport`;
      await this.$axios
        .post(
          url, {}, { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "schedules.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.downloadData = false;
        })
        .catch(() => {
          this.$toast.error('Failed to download', {
            position: "top",
            duration: 7000,
          });
        });
    },
    async deleteTimetable(id, name) {
      if (
        confirm(
          `WARNING: You are going to delete ${name}, this action will also refresh the schedules. Do you want to proceed?`
        ) == true
      ) {
        const url = `${this.$store.getters.getAPIKey.mainAPI}/usersubgrouptimetables/${id}`;
        await axios
          .delete(url)
          .then(() => {
            this.$toast.success('Timetable deleted.', {
              position: "top",
              duration: 3000,
            });
            this.changeSubgroup();
          })
          .catch(() => {
            this.$toast.error('Failed to delete Timetable.', {
              position: "top",
              duration: 3000,
            });
          });
      }
    },
    async getSubgroupSchedules() {
      if (this.sessions.length < 1) await this.getSessions();
      this.viewSubgroupSchedule = false;
      this.datesSessionsSubgroupSchedules = {};
      const url = `${this.$store.getters.getAPIKey.mainAPI}/usersubgroupschedules?usersubgroup=${this.subgroup}&`;
      var schedules = [];
      var holidays = [];
      var suspends = [];
      await axios
        .get(url)
        .then((response) => {
          schedules = response.data.schedules;
          holidays = response.data.holidays;
          suspends = response.data.suspends;
        })
        .catch((error) => {
          error;
        });

      this.suspends = {};
      await suspends.forEach((suspend) => {
        this.suspends[suspend.date] = suspend;
      });
      this.holidays = {};
      await holidays.forEach((holiday) => {
        this.holidays[holiday.date] = holiday.name;
      });

      var structuredScheduleData = {};
      await schedules.forEach((schedule) => {
        if (!(schedule.date in structuredScheduleData)) {
          structuredScheduleData[schedule.date] = {};
          this.sessions.forEach(session => {
            structuredScheduleData[schedule.date][session.id] = [];
          });
        }
        if (!this.subgroupSchedulesEnabledSessionIds.includes(schedule.session_id))
          this.subgroupSchedulesEnabledSessionIds.push(schedule.session_id)
        structuredScheduleData[schedule.date][schedule.session_id].push({
          schedule_id: schedule.id,
          course_id: schedule.course_user.course.id,
          course: schedule.course_user.course.name,
          course_code: schedule.course_user.course.code,
          subgroup_code: schedule.course_user.course.usersubgroup.code,
          instructor: schedule.course_user.institution_user.first_name,
          instructor_fullname:
            schedule.course_user.institution_user.first_name +
            " " +
            schedule.course_user.institution_user.last_name,
          course_user_id: schedule.course_user_id,
          delivery_method: schedule.delivery_method,
          class_type: schedule.class_type,
        });
      });
      // reorder schedule sessions ids
      let tempSubgroupSchedulesEnabledSessionIds = this.subgroupSchedulesEnabledSessionIds;
      this.subgroupSchedulesEnabledSessionIds = [];
      this.sessions.forEach(session => {
        if (tempSubgroupSchedulesEnabledSessionIds.includes(session.id))
          this.subgroupSchedulesEnabledSessionIds.push(session.id);
      });


      this.datesSessionsSubgroupSchedules = structuredScheduleData;
      this.viewSubgroupSchedule = true;
    },
    addSessionToTimetableScheduleTable() {
      const newSessionIndex = this.maxSessions;
      if (newSessionIndex >= this.sessions.length) {
        alert('All sessions are added, please contact institute admin or ezygo support to add more session to your institution');
        return;
      }
      let session = this.sessions[newSessionIndex];
      this.maxSessions++;
      for (const dayIndex in this.courseSchedulesTableHeaders) {
        this.courseSchedulesTableHeaders[dayIndex].sessions[session.id] = session;
        this.courseSchedulesTableData[dayIndex][session.id] = [];
      }

      for (const dayIndex in this.courseSchedulesClasstypeDeliverymethodArray) {
        this.courseSchedulesClasstypeDeliverymethodArray[dayIndex][session.id] = [];
      }

      
    },
    addDayToTimetableScheduleTable() {
      let dayIndex = 0;
      for (const dayIndexes in this.courseSchedulesTableHeaders) {
        let checkIndex = parseInt(dayIndexes);
        dayIndex = dayIndex < checkIndex ? checkIndex : dayIndex;
      }
      dayIndex++;
      this.courseSchedulesTableHeaders[dayIndex] = {
        id: dayIndex,
        name: 'Day ' + dayIndex,
        sessions: {},
      };
      this.courseSchedulesTableData[dayIndex] = {};
      this.sessions.forEach((session, index) => {
        if (index < this.maxSessions) {
          this.courseSchedulesTableData[dayIndex][session.id] = [];
          this.courseSchedulesTableHeaders[dayIndex].sessions[session.id] = session;
        }
      });
      this.$forceUpdate();
    },
    async updateTimetableSchedules() {

      this.actionButton.text = 'Saving...';
      this.actionButton.disabled = true;
      await this.postUpdateTimetable();
      let timetableSchedules = [];
      for (const dayId in this.courseSchedulesTableData) {
        const sessionsSchedules = this.courseSchedulesTableData[dayId];
        for (const sessionId in sessionsSchedules) {
          const schedules = sessionsSchedules[sessionId];
          schedules.forEach(schedule => {
            timetableSchedules.push({
              session_id: sessionId,
              si_no: this.isDayTimetable ? dayId : null,
              workday_id: this.isDayTimetable ? null : dayId,
              course_user_id: schedule.course_user_id,
              usersubgroup_id: this.timetable.usersubgroup_id,
              usersubgroup_timetable_id: this.timetable.id,
              delivery_method_id: this.courseSchedulesClasstypeDeliverymethodArray[dayId][sessionId][schedule.course_id] ? this.courseSchedulesClasstypeDeliverymethodArray[dayId][sessionId][schedule.course_id].delivery_method_id : null,
              class_type_id: this.courseSchedulesClasstypeDeliverymethodArray[dayId][sessionId][schedule.course_id] ? this.courseSchedulesClasstypeDeliverymethodArray[dayId][sessionId][schedule.course_id].class_type_id : null
            });
          });
        }
      }
    
      await axios
        .post(
          this.$store.getters.getAPIKey.mainAPI + "/courseschedules",
          {
            timetable_schedules: timetableSchedules,
            usersubgroup_timetable_id: this.timetable.id,
          }
        )
        .then(() => {
          
        })
        .catch(() => {
          this.$toast.error('Failed to update due to unexpected error, please try later', {
            position: "top",
            duration: 5000,
          });
        });
      this.changeSubgroup();
    },
    deleteCourseFromList(scheduleIndex, dayId, sessionId) {
      this.courseSchedulesTableData[dayId][sessionId][scheduleIndex]=null;
      this.courseSchedulesTableData[dayId][sessionId].splice(scheduleIndex, 1);
      this.$forceUpdate();
    },
    forceUpdateIt: function () {
      this.$forceUpdate();
    },
    async editTimetableSchedule() {
      this.enableEditTimetableSchedule = false;
      await Promise.all([
        this.getSubgroupCourseInstructorArray(), // fill data on this.courseInstructorArray
        this.getUsersubgrouptimetableSchedules(), // fill key and data on this.timetableCourseSchedulesResponse[dayKey][session_id]]
      ]);
      await this.prepareTimetableSchedulesTable();
      this.$forceUpdate();
      this.actionButton.text = 'Submit';
      this.actionButton.disabled = false;
      this.enableEditTimetableSchedule = true;
    },
    async prepareTimetableSchedulesTable() {
      // we have all session and workdays 
      // now we need to create a table with 3 days row for timetable type 'days'
      // or weekdays number of rows
      // and 5 sessions 

      // now load the saved schedule and fill it and if required add more day rows and session columns

      // find maximum session used in schedule or just (6 sessions or less session count)
      let enabledSessionIds = [];
      let enabledCourseIds = [];
      let maxDays = 0; // for days timetable
      this.maxSessions = 0; // the maximum order of session used in table (if data only on 4th session then the value is 5 (order of session from 0-4))
      for (const dayKey in this.timetableCourseSchedulesResponse) {
        if (this.isDayTimetable) {
          // find the maximum day count from schedules respnse else just 3 rows
          maxDays = maxDays < dayKey ? dayKey : maxDays;
        }
        for (const sessionKey in this.timetableCourseSchedulesResponse[dayKey]) {
          if (!enabledSessionIds.includes(sessionKey)) enabledSessionIds.push(sessionKey);
        }
      }
      // this.maxSessions = enabledSessionIds.length;
      // // find the maximum used sessions incase of already have schedule
      for (let index = 0; index < this.sessions.length; index++) {
        const id = this.sessions[index].id;
        if (enabledSessionIds.includes(`${id}`)) this.maxSessions = index + 1;
      }
      if (this.maxSessions == 0) this.maxSessions = (this.sessions.length <= 5) ? this.sessions.length : 5; // default 5 session or less

      this.courseSchedulesTableHeaders = {};
      this.courseSchedulesTableData = {};
      this.courseSchedulesClasstypeDeliverymethodArray = {};


      if (this.isDayTimetable) {
        maxDays = ((maxDays > 0) ? maxDays : 4);
        for (let dayIndex = 1; dayIndex <= maxDays; dayIndex++) {
          this.courseSchedulesTableHeaders[dayIndex] = {
            id: dayIndex,
            name: 'Day ' + dayIndex,
            sessions: {},
          };
          this.courseSchedulesTableData[dayIndex] = {};
          this.courseSchedulesClasstypeDeliverymethodArray[dayIndex] = {};
          this.sessions.forEach((session, index) => {
            if (index < this.maxSessions) {
              this.courseSchedulesTableData[dayIndex][session.id] = [];
              this.courseSchedulesClasstypeDeliverymethodArray[dayIndex][session.id] = [];
              this.courseSchedulesTableHeaders[dayIndex].sessions[session.id] = session;
            }
          });
        }
      } else {
        this.workdays.forEach(day => {
          this.courseSchedulesTableHeaders[day.id] = day;
          this.courseSchedulesTableHeaders[day.id].sessions = {};
          this.courseSchedulesTableData[day.id] = {};
          this.courseSchedulesClasstypeDeliverymethodArray[day.id] = {};
          this.sessions.forEach((session, index) => {
            if (index < this.maxSessions) {
              this.courseSchedulesTableData[day.id][session.id] = [];
              this.courseSchedulesClasstypeDeliverymethodArray[day.id][session.id] = [];
              this.courseSchedulesTableHeaders[day.id].sessions[session.id] = session;
            }
          });
        });
      }

      // console.log('this.maxSessions');
      // console.log(this.maxSessions);
      // console.log('this.timetableCourseSchedulesResponse');
      // console.log(this.timetableCourseSchedulesResponse);

      // console.log('this.courseSchedulesTableData');
      // console.log(this.courseSchedulesTableData);
      // feed data fro api response
      for (const dayKey in this.timetableCourseSchedulesResponse) {
        // if (this.isDayTimetable) 
        for (const sessionKey in this.timetableCourseSchedulesResponse[dayKey]) {
          this.timetableCourseSchedulesResponse[dayKey][sessionKey].forEach(schedule => {
            this.courseSchedulesTableData[dayKey][sessionKey].push(schedule)
          });
        }
      }

      for (const dayKey in this.ClassDeliveryMethodResponse) {
        if (this.isDayTimetable) {
          // find the maximum day count from schedules respnse else just 3 rows
          maxDays = maxDays < dayKey ? dayKey : maxDays;
        }
        for (const sessionKey in this.ClassDeliveryMethodResponse[dayKey]) {
          if (!enabledSessionIds.includes(sessionKey)) enabledSessionIds.push(sessionKey);
          for (const courseKey in this.ClassDeliveryMethodResponse[dayKey][sessionKey]) {
            this.courseSchedulesClasstypeDeliverymethodArray[dayKey][sessionKey][courseKey] = [];
            if (!enabledCourseIds.includes(courseKey)) enabledCourseIds.push(courseKey);
          }
        }
      }

      for (const dayKey in this.ClassDeliveryMethodResponse) {
        for (const sessionKey in this.ClassDeliveryMethodResponse[dayKey]) {
          for (const courseKey in this.ClassDeliveryMethodResponse[dayKey][sessionKey]) {
            this.ClassDeliveryMethodResponse[dayKey][sessionKey][courseKey].forEach(schedule => {
              this.courseSchedulesClasstypeDeliverymethodArray[dayKey][sessionKey][courseKey] = schedule
            });
          }
        }
      }

    },
    async getUsersubgrouptimetableSchedules() {
      const url = `${this.$store.getters.getAPIKey.mainAPI}/courseschedules?usersubgrouptimetable=${this.timetable.id}&`;
      var schedules = [];
      await axios
        .get(url)
        .then((response) => {
          schedules = response.data;
        })
        .catch((error) => {
          error;
        });

      var structuredScheduleData = {};
      var structuredClasstypeDeliverymethodData = {};
    
      await schedules.forEach((schedule) => {
       
        let dayKey = this.isDayTimetable ? schedule.si_no : schedule.workday_id;
        if (!(dayKey in structuredScheduleData)) structuredScheduleData[dayKey] = {};
        if (!(dayKey in structuredClasstypeDeliverymethodData)) structuredClasstypeDeliverymethodData[dayKey] = {};
       
        if (!(schedule.session_id in structuredScheduleData[dayKey])) structuredScheduleData[dayKey][schedule.session_id] = [];
        if (!(schedule.session_id in structuredClasstypeDeliverymethodData[dayKey])) structuredClasstypeDeliverymethodData[dayKey][schedule.session_id]= [];
        if (!(schedule.session_id in structuredClasstypeDeliverymethodData[dayKey][schedule.session_id])) structuredClasstypeDeliverymethodData[dayKey][schedule.session_id][schedule.course_user.course.id] = [];     
        structuredClasstypeDeliverymethodData[dayKey][schedule.session_id][schedule.course_user.course.id].push({
          delivery_method: schedule.delivery_method?schedule.delivery_method.name:null,
          delivery_method_id: schedule.delivery_method_id,
          class_type: schedule.class_type?schedule.class_type.name:null,
          class_type_id: schedule.class_type_id,
        });
        
        structuredScheduleData[dayKey][schedule.session_id].push({
          id: schedule.id,
          course_id: schedule.course_user.course.id,
          course: schedule.course_user.course.name,
          course_code: schedule.course_user.course.code,
          instructor: schedule.course_user.institution_user.first_name,
          instructor_fullname:
            schedule.course_user.institution_user.first_name +
            " " +
            schedule.course_user.institution_user.last_name,
          course_user_id: schedule.course_user_id,
          delivery_method: schedule.delivery_method,
          delivery_method_id: schedule.delivery_method_id,
          class_type_id: schedule.class_type_id,
          class_type: schedule.class_type,
        });
      });
      
     
      this.timetableCourseSchedulesResponse = structuredScheduleData;
      this.ClassDeliveryMethodResponse = structuredClasstypeDeliverymethodData;
    },

    async getSubgroupCourseInstructorArray() {
      this.courseInstructorArray = []; //clear
      const url = `${this.$store.getters.getAPIKey.mainAPI}/usersubgroup/${this.subgroup}/courses/withinstructors?interdepartmental_courses=1&`;

      await axios
        .get(url)
        .then((response) => {
          var coursesInstructors = response.data;
          coursesInstructors.forEach((course) => {
            course.institution_users.forEach((instructor) => {
              this.courseInstructorArray.push({
                course_id: course.id,
                course: course.name,
                course_code: course.code,
                instructor: instructor.first_name,
                instructor_fullname:
                  instructor.first_name + " " + instructor.last_name,
                course_user_id: instructor.course_user_id,
              });
             
            });
          });
          response;
        })
        .catch((error) => {
          error;
        });
    },

    async setDefaultTimetable(index) {
      this.timetable = this.timetables[index];
      this.timetables[index].default_timetable = null;
      this.timetable.default_timetable = true;
      let url = this.$store.getters.getAPIKey.mainAPI + `/usersubgrouptimetables/${this.timetable.id}`;
      await axios.put(url, this.timetable)
        .then(() => {
          this.$toast.success('Updated successfully', {
            position: "top",
            duration: 3000,
          });
          this.getTimetables();
        });
    },
    async createEditTimetable(index) {
      // set this.timetable empty
      // if new timetable- view timetable form only
      // and when procceed save timetable then show schedule

      // if edit- view timetable and schedule


      this.enableEditTimetable = false;
      this.enableEditTimetableSchedule = false;

      // this.timetable = {
      this.timetable.id = null;
      this.timetable.si_no = '1';
      this.name = '';
      this.timetable.type = 'weeks'; //['days', 'weeks']
      this.timetable.description = '';
      this.timetable.excluded_dates = [];// YYYY/MM/DD
      this.timetable.excluded_weeks = []; // SUNDAY, MONDAY,...
      this.timetable.start_date = null;
      this.timetable.upto_date = null;
      this.timetable.default_timetable = false,
        this.timetable.usersubgroup_id = this.subgroup;
      // };

      if (index === null) {
        // create mode
        this.actionButton.text = 'Proceed to edit timetable schedules';
        this.actionButton.disabled = false;
        this.enableEditTimetable = true;
        this.enableEditTimetableSchedule = false;
        this.timetablesAction = 'createEdit';
      } else {
        // edit mode
        this.timetable = this.timetables[index];
        this.actionButton.text = 'Save';
        this.actionButton.disabled = false;
        this.enableEditTimetable = true;
        this.timetablesAction = 'createEdit';
        await this.editTimetableSchedule();
        this.enableEditTimetableSchedule = true;
      }

    },

    async postCreateTimetable() {
      this.actionButton.text = 'Saving...';
      this.actionButton.disabled = true;
      let action = 'post';
      let url = this.$store.getters.getAPIKey.mainAPI + `/usersubgrouptimetables`;
      if (this.timetable.id) {
        action = 'put';
        url = url + `/${this.timetable.id}`;
      }

      await axios[action](url, this.timetable)
        .then((response) => {
          this.timetable.id = response.data.id;
          this.$toast.success('Created/Updated successfully', {
            position: "top",
            duration: 3000,
          });
          this.editTimetableSchedule();
          // this.getTimetables();
        })
        .catch((error) => {
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed to Create";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 3000,
          });

          this.actionButton.text = 'Proceed';
          this.actionButton.disabled = false;
        });
    },

    async postUpdateTimetable() {

      this.actionButton.text = 'Saving...';
      this.actionButton.disabled = true;
      let action = 'put';
      let url = this.$store.getters.getAPIKey.mainAPI + `/usersubgrouptimetables`;
      url = url + `/${this.timetable.id}`;

      await axios[action](url, this.timetable)
        .then((response) => {
          this.timetable.id = response.data.id;
          this.$toast.success('Created/Updated successfully', {
            position: "top",
            duration: 3000,
          });
        })
        .catch((error) => {
          let errortxt = "";
          if (error.response)
            if (error.response.status == 422) {
              let errormsgs = error.response.data.errors;
              for (const key in errormsgs) {
                errortxt = errormsgs[key][0];
              }
            } else {
              errortxt = error.response.data.message;
            }
          else {
            errortxt = "Failed to Create";
          }
          this.$toast.error(errortxt, {
            position: "top",
            duration: 3000,
          });
        });
    },

    async getTimetables() {
      this.timetable = {
        id: null,
        si_no: '', name: '',
        type: '', //['days', 'weeks']
        description: '',
        excluded_dates: [],// YYYY/MM/DD
        excluded_weeks: [], // SUNDAY, MONDAY,...
        start_date: null,
        upto_date: null,
        default_timetable: false,
        usersubgroup_id: null,
      };
      let url = this.$store.getters.getAPIKey.mainAPI +
        `/usersubgrouptimetables?usersubgroup=${this.subgroup}`;
      await axios
        .get(url)
        .then((response) => {
          this.timetables = response.data;
        })
        .catch((error) => {
          error;
        });
      this.timetablesAction = 'list';
    },

    async getSubgroups() {
      let url = this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/usersubgroups`;
      await axios
        .get(url)
        .then((response) => {
          this.subgroups = response.data;
          this.subgroups.forEach((subgroup) => {
            if (!this.subgroupOptions[subgroup.usergroup.id])
              this.subgroupOptions[subgroup.usergroup.id] = {
                name: subgroup.usergroup.name +' '+ subgroup.usergroup.scheme,
                subgroups: [],
              };
            if(subgroup.type !='interdepartment_courses_group')this.subgroupOptions[subgroup.usergroup.id].subgroups.push({
              value: subgroup.id,
              text: subgroup.name,
            });
          });
          if (this.subgroups.length) {
            this.subgroup = this.subgroups[0].id;
            this.changeSubgroup();
          }
        })
        .catch((error) => {
          error;
        });
    },
    // clear all data on changeSubgroup--------------------------------------------------------------
    async changeSubgroup() {
      await this.getTimetables();
      await this.getSubgroupSchedules();
    },
    //get delivery methods amd class types
    async getDeliverymethodsClassTypes() {
      this.courseDeliveryMethods = [];
      this.classTypes = [];
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/coursedeliverymethods`;
      await axios
        .get(url)
        .then((response) => {
          response.data.forEach(method => {
            this.courseDeliveryMethods.push({
              value: method.id, text: method.name
            });
          });
        })
        .catch((error) => {
          error;
        });
      const url1 =
        this.$store.getters.getAPIKey.mainAPI +
        `/classtypes`;
      await axios
        .get(url1)
        .then((response) => {
          response.data.forEach(classType => {
            this.classTypes.push({
              value: classType.id, text: classType.name
            });
          });
        })
        .catch((error) => {
          error;
        });
    },
    async getSessions() {
      this.sessionObjects = {};
      const url = this.$store.getters.getAPIKey.createSessions;
      await axios
        .get(url)
        .then((response) => {
          this.sessions = response.data;
          this.sessions.forEach(session => {
            this.sessionObjects[session.id] = session;
          });
        })
        .catch((error) => {
          error;
        });
    },
    async getWorkdays() {
      this.workdayObjects = {};
      const url = this.$store.getters.getAPIKey.createworkdays;
      await axios
        .get(url)
        .then((response) => {
          this.workdays = response.data;
          this.workdays.forEach(workday => {
            this.workdayObjects[workday.id] = workday;
          });
        })
        .catch((error) => {
          error;
        });
    },

  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}

.holiday-row {
  background-color: rgb(214, 214, 106) !important;
}

.suspended-cell {
  background-color: rgb(244, 170, 162) !important;
}

.badge-red {
  color: rgb(0, 0, 0);
  background-color: #fdd4d6;
}

.badge-orange {
  color: rgb(0, 0, 0);
  background-color: #fce7d5;
}

.badge-yellow {
  color: rgb(0, 0, 0);
  background-color: #fcf4c3;
}

.badge-green {
  color: rgb(0, 0, 0);
  background-color: #e8fcec;
}

.badge-blue {
  color: rgb(0, 0, 0);
  background-color: #e2f0fd;
}

.badge-pink {
  color: rgb(0, 0, 0);
  background-color: #facde8;
}

.badge-purple {
  color: rgb(0, 0, 0);
  background-color: #f2d9fb;
}

.badge-brown {
  color: rgb(0, 0, 0);
  background-color: #beb7aa;
}

.badge-black {
  color: rgb(0, 0, 0);
  background-color: #b1b1b1;
}

.badge-white {
  color: rgb(0, 0, 0);
  background-color: #f4f4f4;
}
</style>
